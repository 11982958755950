import { Col, Container, Row } from "react-grid-system";
import figure from "../assets/welcome figure.png";

export default function Home() {
    return (
        <Container style={{marginTop: "20px", marginBottom: "40px"}}>
            <Row>
               <Col md={8}>
                        <h1 style={{fontWeight: "normal", marginBottom: "2px", fontSize: "30px"}}>Welcome to i<strong>NC</strong>lusive!</h1>
                        <p style={{marginBottom: "20px", fontSize: "18px"}}>The repository containing all important information you need to
                        include <strong>N</strong>on-<strong>C</strong>anonical AAs into your
                        research</p>
                        <p>In nature, proteins assembled by the ribosome contain only 22 amino
                        acids (AAs), which are called <em>canonical</em> or <em>proteinogenic</em> AAs. Yet, there are more AAs in nature, which we
                        refer to as <em>non-canonical AAs</em> (ncAAs), some of which are for
                        instance incorporated into non-ribosomal peptides assembled by special
                        mega-enzymes – the non-ribosomal peptide synthetases. Moreover,
                        scientists can chemically synthesize in the laboratory unnatural AAs
                        equipped with desired properties.</p>
                        <p>About three decades ago scientists embarked on a quest to expand the
                        natural proteome with proteins bearing ncAAs.</p>
                        <p>There are many ways to incorporate a ncAA into a protein of interest,
                        from chemically aminoacylating the tRNA, to using auxotrophic strains
                        with promiscuous synthetases fed with ncAAs (residue-specific
                        incorporation of ncAAs, <a rel="noreferrer" target="_blank"
                        href="https://pubmed.ncbi.nlm.nih.gov/21071259/">Johnson et
                        al.</a>, Curr Opin Chem Biol, 201), to expressing in cells aaRS/tRNA
                        pairs orthogonal to the endogenous ones. Since most often ncAA
                        incorporation is connected with the repurposing of a codon to this task
                        (involving a genetic component which is new to nature), one speaks of
                        genetic code expansion (GCE).</p>
                        <p>Here we will briefly describe the – so far – most common method for
                        site-specific ncAA incorporation: suppression of nonsense codons (more
                        rarely sense codon reassignment) by a natural/engineered tRNA loaded
                        with the ncAA of choice by a natural/engineered cognate aminoacyl-tRNA
                        synthetase. For the other methods please refer to <a rel="noreferrer" target="_blank"
                        href="https://www.nature.com/articles/s41576-020-00307-7">Torre and
                        Chin</a>, Nat Rev Genet, 2021, <a rel="noreferrer" target="_blank"
                        href="https://www.sciencedirect.com/science/article/abs/pii/S0022283621006823">Gamper
                        et al.</a>, J Mol Biol, 2022, <a rel="noreferrer" target="_blank"
                        href="https://pubmed.ncbi.nlm.nih.gov/35729073/">Katoh and
                        Suga</a>, Ann Rev Biochem, 2022, and <a rel="noreferrer" target="_blank"
                        href="https://pubs.rsc.org/en/content/articlelanding/2020/cs/d0cs00457j">Kimoto
                        and Hirao</a>, Chem Soc Rev, 2020.</p>
                        <p>In a nutshell, the site-specific incorporation of a ncAA into a
                        protein of interest via the codon suppression technology in a living
                        cell is achieved by expressing in a host cell a tRNA that can be charged
                        with the ncAA by a cognate aminoacyl-tRNA synthetase (aaRS), which is
                        orthogonal to the endogenous aaRSs and does not charge endogenous tRNAs
                        with the ncAA or the cognate tRNA with canonical AAs (Figure 1).</p>
                        <div style={{textAlign: "center", marginBottom: "30px", marginTop: "30px"}}>
                            <div style={{width: "100%"}}>
                                <img src={figure} alt="" style={{width: "70%", maxWidth: "1000px"}}/>
                            </div>
                            <div>
                                <p style={{display: "block"}}>
                                <b>Figure 1.</b> Schematic representation of ncAA incorporation based on codon suppression
                                </p>
                            </div>
                        </div>
                        <p>For a more detailed explanation on the methodology behind ncAA
                        incorporation, please refer to <a rel="noreferrer" target="_blank"
                        href="https://pubs.acs.org/doi/abs/10.1021/acschembio.7b00974">Young
                        and Schultz</a>, ACS Chem Biol, 2018.</p>
                        <p>Despite the impressive achievements in this field, a centralized
                        repository to document the progress made thus far was lacking, making it
                        difficult and time-consuming to gather the relevant information from the
                        literature needed to embark in this type of experiments.</p>
                        <p>iNClusive fills this gap, providing a comprehensive database with
                        crucial information which shall facilitate future research dealing with
                        the incorporation of ncAAs into proteins.</p>
                        <p>The database incorporates <strong>manually curated</strong> data from more than 600 peer-reviewed publications in which a tRNA synthetase was used to load the ncAA onto the tRNA and where the evidence of ncAA incorporation into the protein was derived from mass spectrometry experiments. This choice leaves out many valuable publications. However, given how time-intense and laborious the data extraction process was, we had to compromise between exhaustiveness and manageability.</p>
                        <p>We have taken great care in curating the information to ensure its accuracy and usability by the scientific community. Nonetheless, mistakes are bound to be present! As we said, the amount of data we handled was vast and our team was heterogeneous in composition (career stage, background). We encourage you to notify us whenever you find inaccuracies and errors (email to: <a href="mailto: inclusive@bio.uni-freiburg.de">inclusive@bio.uni-freiburg.de</a>).</p>
                        <p>The searchable
                        interface of our database includes the following information:</p>
                        <ul>
                            <li>ncAA abbreviation(s) used in the publication</li>
                            <li>ncAA name, as mentioned in the publication</li>
                            <li>ncAA IUPAC name</li>
                            <li>ncAA SMILES notation</li>
                            <li>ncAA chemical formula</li>
                            <li>ncAA link for Pubchem, if available</li>
                            <li>Canonical amino acid most similar to the ncAA</li>
                            <li>Identifier of the aminoacyl-tRNA synthetase (aaRS). This is composed of the following information:  abbreviation of the organism from which the aaRS was taken; natural substrate of the aaRS; the letters “RS”; mutations (if any)</li>
                            <li>Amino acid sequence of (mutated) aaRS</li>
                            <li>tRNA identifier. This is composed of the following information: abbreviation of organism from which the tRNA comes from; tRNA; AA naturally transported by the tRNA; anticodon recognized.</li>
                            <li>tRNA organism(s) of origin</li>
                            <li>tRNA sequence</li>
                            <li>Protein into which the ncAA was incorporated</li>
                            <li>Position of ncAA incorporation</li>
                            <li>Organism(s) in which the incorporation was tested, if any (could
                            be in vitro)</li>
                            <li>Publication in APA style citation format</li>
                            <li>DOI link to the publication</li>
                        </ul>
                        <p>Please note that modifications of the same protein at different
                        positions by the same ncAA are grouped as a single entry, while
                        modifications of the same protein with different ncAAs or incorporation
                        of the same ncAA into different proteins appear as separate entries,
                        even if the publication describing them is the same.</p>
                        <p>Before using the database, we suggest you visit the pages “Useful information” and “Usage examples”.</p>
                        <p>Given the rapid pace of advancements in this field, this project is
                        an ongoing endeavor!</p>
                        <p>We aim to continue to update this database every six months with new
                        entries from the literature, however…</p>
                        <p><strong>Your input is welcome!</strong> If you would like to see new
                        entries in the database, reach out to us at: <a href="mailto: inclusive@bio.uni-freiburg.de">inclusive@bio.uni-freiburg.de</a>.
                        Consider, though, that we will include only publications where the incorporation was proven by MS.</p>
               </Col> 
               <Col md={4} style={{marginTop: "10px", fontSize: "0.8rem"}}>
                    <h2>Citation</h2>
                    <p><b>iNClusive: a database collecting useful information on non-canonical amino acids and their incorporation into proteins for easier genetic code expansion implementation</b></p>
                    <p>L.-S. Icking, A. M. Riedlberger, F. Krause, J. Widder, A. S. Frederiksen, F. Stockert, M. Spädt, N. Edel, D. Armbruster, G. Forlani, S. Franchini, P. Kaas, B. M. Kırpat Konak, F. Krier, M. Lefebvre, D. Mazraeh, J. Ranniger, J. Gerstenecker, P. Gescher, K. Voigt, P. Salavei, N. Gensch, B. Di Ventura, M. A. Öztürk</p>
                    <p><i>Nucleic Acids Research, 2023</i></p>
                    <p><a rel="noreferrer" target="_blank" href="https://doi.org/10.1093/nar/gkad1090">https://doi.org/10.1093/nar/gkad1090</a></p>
                    <h2>Contributions</h2>
                    <p style={{marginBottom: "0px"}}>Entries were manually curated by (names in alphabetical order):</p>
                    <p>Daniel Armbruster, Nikita Edel, Giada Forlani, Selene Franchini, Anne
                    Frederiksen, Leon-Samuel Icking, Paulina Kaas, Büşra Merve Kirpat,
                    Fabrice Krier, Maïwenn Lefebvre, Daniel Mazraeh, Mehmet Ali Öztürk,
                    Andreas Martin Riedlberger, Michael Spädt, Fabian Stockert, and Jonas
                    Widder</p>
                    <br/>
                    <p style={{marginBottom: "0px"}}>This website was implemented by:</p>
                    <p>Fabian Krause</p>
                    <br/>
                    <p style={{marginBottom: "0px"}}>This website is maintained by:</p>
                    <p>Karsten Voigt</p>
                    <br/>
                    <p style={{marginBottom: "0px"}}>The project was supervised by:</p>
                    <p>Barbara Di Ventura and Mehmet Ali Öztürk (University of Freiburg; <a rel="noreferrer" target="_blank" href="https://diventura.biologie.uni-freiburg.de">https://diventura.biologie.uni-freiburg.de</a>)</p>
               </Col>
            </Row>
        </Container>
    )
}