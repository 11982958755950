import { Col, Container, Row } from "react-grid-system";

export default function ContactUs() {
    return (
        <Container style={{marginTop: "20px", marginBottom: "40px"}}>
            <Row>
                <Col lg={8}>
                    <h2>Contact us</h2>
                    <p>
                        Despite our effort to report only correct and accurate information, since the database was manually curated, and the information retrieval was far from trivial, mistakes are bound to be present! 
                        If you find inaccuracies or mistakes, do not hesitate to let us know! We welcome your feedback and corrections. Email us at this address: <a href="mailto: inclusive@bio.uni-freiburg.de">inclusive@bio.uni-freiburg.de</a>.
                    </p>
                    <p>
                        Moreover, if you wish to support us and help iNClusive grow, you can yourself extract the necessary information from your favorite publications (either some we might have inadvertently missed or future ones). We will be happy to add these entries to the database, provided they pass our quality check and the publications follow our criteria (a tRNA synthetase was used and the proof of incorporation was done via MS).
                    </p>
                </Col>
            </Row>
        </Container>
    )
}