import { Col, Container, Row } from "react-grid-system";
import example1_1 from "../assets/example1_1.png"
import example1_2 from "../assets/example1_2.png"
import example1_3 from "../assets/example1_3.png"
import example1_4 from "../assets/example1_4.png"
import example1_5 from "../assets/example1_5.png"
import example1_6 from "../assets/example1_6.png"

import example2_1 from "../assets/example2_1.png"
import example2_2 from "../assets/example2_2.png"

import example3_1 from "../assets/example3_1.png"
import example3_2 from "../assets/example3_2.png"

export default function Examples() {
    return (
        <Container style={{marginTop: "20px", marginBottom: "40px"}}>
            <Row>
                <Col lg={8}>
                    <h2>Usage Examples</h2>
                    <p>
                        We will show here below how to use iNClusive for a few specific research questions, in the hope these examples will clarify some issues you may have and allow you to navigate the database more confidently.
                    </p>
                    <h3 style={{marginBottom: "2px"}}><i>Example 1</i></h3>
                    <p><b>Find out if my protein of interest was ever modified with a ncAA and, if so, at which position(s). Let us consider ubiquitin.</b></p>
                    <p>As first thing, we go to the database and click on “Filter data”, which is found on the top left:</p>
                    <div style={{textAlign: "center", marginBottom: "30px", marginTop: "30px", border: "1px solid gray"}}>
                        <div style={{width: "100%"}}>
                            <img src={example1_1} alt="" style={{width: "100%", maxWidth: "1200px"}}/>
                        </div>
                    </div>
                    <p>Now we write the name of the protein in the white box. We also select from the top-down menu we get after clicking “All”: “Tested in (protein)”:</p>
                    <div style={{textAlign: "center", marginBottom: "30px", marginTop: "30px", border: "1px solid gray"}}>
                        <div style={{width: "100%"}}>
                            <img src={example1_2} alt="" style={{width: "100%", maxWidth: "1200px"}}/>
                        </div>
                    </div>
                    <p>At this stage, we are still visualizing all the information for each entry. By clicking on “Show/Hide columns” we can select which information should be displayed at this stage. For example, right now we are only interested in knowing which ncAAs were incorporated into ubiquitin and at which position(s) and the corresponding publications. Since there are 9 hits for ubiquitin (see numbers just above the first raw), it might be these are fusions with other proteins, or ubiquitin from different organisms.
                        We therefore make selections of columns. To be faster, when we only want to have few columns, we first deselect all by clicking on “Hide all”, then click back the boxes corresponding to the columns we are interested in:</p>
                    <p>Click on “Show/Hide columns”. This is what you see before hiding all columns:</p>
                    <div style={{textAlign: "center", marginBottom: "30px", marginTop: "30px", border: "1px solid gray"}}>
                        <div style={{width: "100%"}}>
                            <img src={example1_3} alt="" style={{width: "100%", maxWidth: "1200px"}}/>
                        </div>
                    </div>
                    <p>Now click on “Hide all”. This is what you see: </p>
                    <div style={{textAlign: "center", marginBottom: "30px", marginTop: "30px", border: "1px solid gray"}}>
                        <div style={{width: "100%"}}>
                            <img src={example1_4} alt="" style={{width: "100%", maxWidth: "1200px"}}/>
                        </div>
                    </div>
                    <p>You can now select the columns you are interested in:</p>
                    <div style={{textAlign: "center", marginBottom: "30px", marginTop: "30px", border: "1px solid gray"}}>
                        <div style={{width: "100%"}}>
                            <img src={example1_5} alt="" style={{width: "100%", maxWidth: "1200px"}}/>
                        </div>
                    </div>
                    <p>At this stage, you can scroll down and look at all the 9 entries, to see if they are all the same ubiquitin or not. The colors correspond to the different publications. Like this, it is very easy for you to identify cases in which the same protein was modified in a single study many times. For instance, for ubiquitin, we see there are several entries with the same colors.
                        With the current information being displayed, we cannot tell why they are different entries. You can now include more information, for instance the tRNA synthetase and tRNA that were used. You will see they are different, and this is why they appear as different entries despite the ncAA being the same:</p>
                    <div style={{textAlign: "center", marginBottom: "30px", marginTop: "30px", border: "1px solid gray"}}>
                        <div style={{width: "100%"}}>
                            <img src={example1_6} alt="" style={{width: "100%", maxWidth: "1200px"}}/>
                        </div>
                    </div>
                    <p>By analyzing the entries, you can now find out which ncAAs have been incorporated at which positions in ubiquitin. Just play around with showing/hiding columns to explore the different categories iNClusive offers to you.</p>
                    <h3 style={{marginBottom: "2px"}}><i>Example 2</i></h3>
                    <p><b>Search for synthetases that might enable the incorporation of new ncAAs, which are tyrosine derivatives. </b></p>
                    <p>Start off by filtering the data for ncAAs which are similar to tyrosine. You can do this by selecting in “Filter Data”, “Canonical amino acid most similar to ncAA”, and writing tyrosine in the white box:</p>
                    <div style={{textAlign: "center", marginBottom: "30px", marginTop: "30px", border: "1px solid gray"}}>
                        <div style={{width: "100%"}}>
                            <img src={example2_1} alt="" style={{width: "100%", maxWidth: "1200px"}}/>
                        </div>
                    </div>
                    <p>There are 67 ncAAs resulting from this search. You can order the entries based on name now, for instance. Click on “ncAA abbreviation(s) used in the publication”. The color code groups entries corresponding to the same paper.</p>
                    <div style={{textAlign: "center", marginBottom: "30px", marginTop: "30px", border: "1px solid gray"}}>
                        <div style={{width: "100%"}}>
                            <img src={example2_2} alt="" style={{width: "100%", maxWidth: "1200px"}}/>
                        </div>
                    </div>
                    <p>The ncAAs you would like to incorporate are likely not in the database, because you are working on them, thus they are novel. Nonetheless, by analyzing the structure of those ncAAs, which have been previously incorporated into proteins, you might find some that resemble your target ncAAs. In this case, you might want to test if the synthetases previously used with those ncAAs work with your target ncAAs, too. This could save you precious time because you would not have to evolve the synthetase. </p>
                    <h3 style={{marginBottom: "2px"}}><i>Example 3</i></h3>
                    <p><b>I want to know with how many different synthetases a certain ncAA has been incorporated into proteins.</b></p>
                    <p>Go to “Filter Data” and select “ncAA abbreviation(s) used in the publication” and write “ONBY” in the white box:</p>
                    <div style={{textAlign: "center", marginBottom: "30px", marginTop: "30px", border: "1px solid gray"}}>
                        <div style={{width: "100%"}}>
                            <img src={example3_1} alt="" style={{width: "100%", maxWidth: "1200px"}}/>
                        </div>
                    </div>
                    <p>Now you can select which columns to show. Click on “Show/Hide columns” and make your selections. Since in this case we are interested in the aaRSs, we can hide all the rest and focus on ncAA abbreviation, the IUPAC name for instance, and the aaRS, plus the publication:</p>
                    <div style={{textAlign: "center", marginBottom: "30px", marginTop: "30px", border: "1px solid gray"}}>
                        <div style={{width: "100%"}}>
                            <img src={example3_2} alt="" style={{width: "100%", maxWidth: "1200px"}}/>
                        </div>
                    </div>
                    <p>Keep in mind that some entries may appear redundant to you, because iNClusive is not built around the ncAA only! We considered a separate entry any time one of the item was different, for instance the protein in which the ncAA was incorporated, the aaRS used was different, or the organism into which the experiment was done was different. Nonetheless, by showing/hiding various columns you should be able to retrieve the information you are looking for!</p>         
                </Col>
            </Row>
        </Container>
    )
}