import React, { Fragment } from 'react';
import Papa from "papaparse";
import { HTMLTable, Card, FormGroup, InputGroup, MenuItem, Button, Icon, AnchorButton, Checkbox, Collapse, Tooltip, Colors } from "@blueprintjs/core"
import { Row, Col, Container } from "react-grid-system"
import { ItemRenderer, Select2 } from "@blueprintjs/select"
import ReactPaginate from 'react-paginate';
import { AiFillApple } from 'react-icons/ai';
import { MdColorLens } from 'react-icons/md';
import { PiArrowSquareLeft, PiArrowSquareRight, PiArrowSquareUp, PiArrowSquareDown } from 'react-icons/pi';

interface Filter {
    index: number,
    name: string
}

interface AppliedFilter {
    index: number,
    filter: string
}

const renderFilter: ItemRenderer<Filter> = (filter, { handleClick, handleFocus, modifiers, query }) => {
    if (!modifiers.matchesPredicate) {
        return null;
    }
    return (
        <MenuItem
            active={modifiers.active}
            disabled={modifiers.disabled}
            key={filter.index}
            onClick={handleClick}
            onFocus={handleFocus}
            roleStructure="listoption"
            text={`${filter.name}`}
        />
    );
}


function Table() {
    const [data, setData] = React.useState<any[]>();
    const [fields, setFields] = React.useState<string[]>();
    const [filters, setFilters] = React.useState<Filter[]>();
    const [filteredData, setFilteredData] = React.useState<any[]>([]);
    const [fastaIndex, setFastaIndex] = React.useState(-1);
    const [structureIndex, setStructureIndex] = React.useState(-1);
    const [page, setPage] = React.useState<number>(0);
    const perPage = 25;
    const [maxPages, setMaxPages] = React.useState<number>(10);
    const [sortedFieldString, setSortedFieldString] = React.useState<string>("Publication");
    const [sortedFieldAscending, setSortedFieldAscending] = React.useState<boolean>(true);
    const [modifiedDate, setModifiedDate] = React.useState<Date|undefined>();

    const [displayedFields, setDisplayedFields] = React.useState<number[]>([]);
    const [columnsCollapsed, setColumnsCollapsed] = React.useState<boolean>(false);
    const [filtersCollapsed, setFiltersCollapsed] = React.useState<boolean>(false);

    const [selectedFilters, setSelectedFilters] = React.useState<AppliedFilter[]>([{ index: -1, filter: "" }]);

    const [numberOfUniquePapersTotal, setNumberOfUniquePapersTotal] = React.useState<number>(0);
    const [numberOfProteinsTotal, setNumberOfProteinsTotal] = React.useState<number>(0);
    const [numberOfUniqueSynthetasePairsTotal, setNumberOfUniqueSynthetasePairsTotal] = React.useState<number>(0);
    const [numberOfUniqueNcAATotal, setNumberOfUniqueNcAATotal] = React.useState<number>(0);

    const [numberOfUniquePapersFiltered, setNumberOfUniquePapersFiltered] = React.useState<number>(0);
    const [numberOfProteinsFiltered, setNumberOfProteinsFiltered] = React.useState<number>(0);
    const [numberOfUniqueSynthetasePairsFiltered, setNumberOfUniqueSynthetasePairsFiltered] = React.useState<number>(0);
    const [numberOfUniqueNcAAFiltered, setNumberOfUniqueNcAAFiltered] = React.useState<number>(0);

    const [paperToColorMap, setPaperToColorMap] = React.useState<Map<string, string>>(new Map<string, string>())

    // const colors = [Colors.BLUE5, Colors.GREEN5, Colors.ORANGE5, Colors.VERMILION5, Colors.RED5, Colors.TURQUOISE5, Colors.VIOLET5, Colors.GOLD5];
    // blue, green, gold, red
    // const colors = ["rgba(138, 187, 255, 0.3)", "rgba(114, 202, 155, 0.3)", "rgba(251, 208, 101, 0.3)", "rgba(250, 153, 156, 0.3)"]
    const colors = ["rgb(220,235,255)", "rgb(213,239,225)", "rgb(254,241,209)", "rgb(254,224,225)"]
    
    React.useEffect(() => {
        async function getData() {
            const response = await fetch('/entries.csv')
            let lastModified = response.headers.get("Last-Modified");
            if (lastModified !== null) {
                setModifiedDate(new Date(lastModified));
            }
            const reader = response.body!.getReader()
            const decoder = new TextDecoder('utf-8')

            let rawText = ""
            while (true) {
                const { value, done } = await reader.read()
                const csv = decoder.decode(value)
                rawText = rawText.concat(csv)
                if (done) { break; }
            }

            // const results = Papa.parse(rawText, { header: true, delimiter: "´", quoteChar: "§" })
            const results = Papa.parse(rawText, { header: true, delimiter: ";", quoteChar: '"' })
            // const results = Papa.parse(rawText, { header: true, delimiter: '\t' })
            let rows = results.data.filter((val: any) => val !== undefined && val !== null && val !== "" && val["ID"] !== "");
            rows = rows.map((val: any) => {
                let pubchem = val["ncAA link for Pubchem, if available"] ?? ""
                if (pubchem !== undefined && pubchem !== "not available" && pubchem.trim().startsWith("http")) {
                    // if link is to pubchem site
                    if (pubchem.trim().includes("pubchem.ncbi.nlm.nih.gov"))
                        val["ncAA Structure"] = `https://pubchem.ncbi.nlm.nih.gov/image/imgsrv.fcgi?cid=${pubchem.trim().split("/").slice(-1)[0]}&t=l`;
                    // else if link is to chemspider site
                    if (pubchem.trim().includes("chemspider.com")) {
                        // extract 8119414 from "http://www.chemspider.com/Chemical-Structure.8119414.html", if not possible, set to not available
                        let id = pubchem.trim().split("/").slice(-1)[0].split(".")[1];
                        val["ncAA Structure"] = `http://www.chemspider.com/ImagesHandler.ashx?id=${id}&w=300`;
                    }
                } else {
                    val["ncAA Structure"] = "not available";
                }
                return val;
            });

            // Sort by publication
            rows.sort((a: any, b: any) => {
                if (a["Publication"] === undefined) return 1;
                if (b["Publication"] === undefined) return -1;
                if (a["Publication"] < b["Publication"]) return -1;
                if (a["Publication"] > b["Publication"]) return 1;
                return 0;
            });

            let fields = results.meta.fields!;
            // Change order of keys for rows to match fields
            rows = rows.map((val: any) => {
                let newval: any = {};
                fields.forEach((field: string) => {
                    newval[field] = val[field];
                });
                return newval;
            });

            setData(rows);
            setFields(fields)
            setDisplayedFields(fields.map((val, idx) => idx));
            setFastaIndex(fields?.indexOf("Amino acid sequence of (mutated) aaRS") ?? -1)
            setStructureIndex(fields?.indexOf("ncAA Structure") ?? -1)
            let newFilters = fields?.map(
                (val: string, idx: number) => { return { name: val, index: idx } }
            );
            newFilters = newFilters.filter(v => v.name !== "Structure" && v.name !== "DOI")
            setFilters(newFilters);
            setFilters([{ index: -1, name: "All" }, ...(newFilters ?? [])])
            setFilteredData(rows);
            setMaxPages(Math.ceil(rows.length / perPage))

            // Set total counters
            setNumberOfUniquePapersTotal(rows?.map((v: any) => v["Publication"].toLocaleLowerCase()).filter((v, i, a) => a.indexOf(v) === i).length ?? 0);
            setNumberOfProteinsTotal(rows?.map((v: any) => v["Tested in (protein)"].toLocaleLowerCase()).filter((v, i, a) => a.indexOf(v) === i).length ?? 0);
            setNumberOfUniqueNcAATotal(rows?.map((v: any) => v["ncAA IUPAC name"].toLocaleLowerCase()).filter((v, i, a) => a.indexOf(v) === i).length ?? 0);
            setNumberOfUniqueSynthetasePairsTotal(
                rows?.map((v: any) => {
                    return (v["aaRS ID (abbr. organism, abbr. natural substrate, RS, mutations if any)"] + v["tRNA sequence"]).toLocaleLowerCase()
                }
            ).filter((v, i, a) => a.indexOf(v) === i).length ?? 0);

            // Set initial filtered counters
            setNumberOfUniquePapersFiltered(rows?.map((v: any) => v["Publication"].toLocaleLowerCase()).filter((v, i, a) => a.indexOf(v) === i).length ?? 0);
            setNumberOfProteinsFiltered(rows?.map((v: any) => v["Tested in (protein)"].toLocaleLowerCase()).filter((v, i, a) => a.indexOf(v) === i).length ?? 0);
            setNumberOfUniqueNcAAFiltered(rows?.map((v: any) => v["ncAA IUPAC name"].toLocaleLowerCase()).filter((v, i, a) => a.indexOf(v) === i).length ?? 0);
            setNumberOfUniqueSynthetasePairsFiltered(
                rows?.map((v: any) => {
                    return (v["aaRS ID (abbr. organism, abbr. natural substrate, RS, mutations if any)"] + v["tRNA sequence"]).toLocaleLowerCase()
                }
            ).filter((v, i, a) => a.indexOf(v) === i).length ?? 0);

            // Set paper to color map
            updateColors(rows);

        }
        getData()
    }, [])

    function updateColors(data: any) {
        // Set paper to color map
        let newPaperToColorMap = new Map<string, string>();
        let colorIndex = 0;
        // Assign each paper a color, cycling through the colors
        data.forEach((val: any, idx: number) => {
            if (!newPaperToColorMap.has(val["Publication"])) {
                newPaperToColorMap.set(val["Publication"], colors[colorIndex % colors.length]);
                colorIndex++;
            }
        });
        setPaperToColorMap(newPaperToColorMap);
    }

    function updateFilter(filterString: string, filterIdx: number, selectedFilterIdx: number = 0) {
        let selectedFiltersOld = [...selectedFilters];
        selectedFiltersOld[selectedFilterIdx] = { index: filterIdx, filter: filterString };
        setSelectedFilters(selectedFiltersOld);
        applySelectedFilters(selectedFiltersOld);
    }

    function applySelectedFilters(selectedFilters: AppliedFilter[]) {
        // No filters? Return all data.
        if (selectedFilters.length === 0) {
            let newFilteredData = data;

            // Set filtered counters
            setNumberOfUniquePapersFiltered(newFilteredData?.map(v => v["Publication"].toLocaleLowerCase()).filter((v, i, a) => a.indexOf(v) === i).length ?? 0);
            setNumberOfProteinsFiltered(newFilteredData?.map(v => v["Tested in (protein)"].toLocaleLowerCase()).filter((v, i, a) => a.indexOf(v) === i).length ?? 0);
            setNumberOfUniqueNcAAFiltered(newFilteredData?.map(v => v["ncAA IUPAC name"].toLocaleLowerCase()).filter((v, i, a) => a.indexOf(v) === i).length ?? 0);
            setNumberOfUniqueSynthetasePairsFiltered(
                newFilteredData?.map((v: any) => {
                    return (v["aaRS ID (abbr. organism, abbr. natural substrate, RS, mutations if any)"] + v["tRNA sequence"]).toLocaleLowerCase()
                }
            ).filter((v, i, a) => a.indexOf(v) === i).length ?? 0);

            updateColors(data);

            setFilteredData(data ?? []);
            setMaxPages(Math.ceil((data?.length ?? 0) / perPage))
            return;
        }

        let newFilteredData: any[] | undefined = data?.filter(val => {
            let values: string[] = Object.values(val);
            
            return selectedFilters.every(f => {
                if (f.filter === "") return true;
                if (f.index === -1) return values.some(v => {
                    if (f.filter.includes(";")) {
                        let filters = f.filter.split(";");
                        filters = filters.filter(v => v !== "").map(v => v.trim());
                        return filters.some(filter => v.toLocaleLowerCase().includes(filter.toLocaleLowerCase()))
                    }
                    return v.toLocaleLowerCase().includes(f.filter.toLocaleLowerCase())
                })
                if (f.filter.includes(";")) {
                    let filters = f.filter.split(";");
                    filters = filters.filter(v => v !== "").map(v => v.trim());
                    return filters.some(filter => values.at(f.index)?.toLocaleLowerCase().includes(filter.toLocaleLowerCase()))
                }
                return values.at(f.index)?.toLocaleLowerCase().includes(f.filter.toLocaleLowerCase())
            })
        })
        
        setFilteredData(newFilteredData ?? []);
        setMaxPages(Math.ceil((newFilteredData?.length ?? 0) / perPage))
        setPage(0)

        // Set filtered counters
        setNumberOfUniquePapersFiltered(newFilteredData?.map(v => v["Publication"].toLocaleLowerCase()).filter((v, i, a) => a.indexOf(v) === i).length ?? 0);
        setNumberOfProteinsFiltered(newFilteredData?.map(v => v["Tested in (protein)"].toLocaleLowerCase()).filter((v, i, a) => a.indexOf(v) === i).length ?? 0);
        setNumberOfUniqueNcAAFiltered(newFilteredData?.map(v => v["ncAA IUPAC name"].toLocaleLowerCase()).filter((v, i, a) => a.indexOf(v) === i).length ?? 0);
        setNumberOfUniqueSynthetasePairsFiltered(
            newFilteredData?.map((v: any) => {
                return (v["aaRS ID (abbr. organism, abbr. natural substrate, RS, mutations if any)"] + v["tRNA sequence"]).toLocaleLowerCase()
            }
        ).filter((v, i, a) => a.indexOf(v) === i).length ?? 0);

        updateColors(newFilteredData);
    }
    
    function makeLinkIfLink(text: string) {
        if (text === undefined) return text;
        if (text.trim().startsWith("http")) {
            return <a rel='noreferrer' target="_blank" href={text.trim()}>{text.trim()}</a>
        } else {
            return <p>{text}</p>;
        }
    }

    function sortByField(field: string, ascending: boolean) {
        let newFilteredData = [...filteredData];
        if (field === "ID") {
            newFilteredData.sort((a, b) => {
                if (a[field] === undefined) return 1;
                if (b[field] === undefined) return -1;
                if (parseInt(a[field]) < parseInt(b[field])) return ascending ? -1 : 1;
                if (parseInt(a[field]) > parseInt(b[field])) return ascending ? 1 : -1;
                return 0;
            });
        } else {
            newFilteredData.sort((a, b) => {
                if (a[field] === undefined) return 1;
                if (b[field] === undefined) return -1;
                if (a[field] < b[field]) return ascending ? -1 : 1;
                if (a[field] > b[field]) return ascending ? 1 : -1;
                return 0;
            });
        }
        setPage(0);
        setSortedFieldString(field);
        setSortedFieldAscending(ascending);
        setFilteredData(newFilteredData);
    }

    function downloadFilteredData() {
        let csv = Papa.unparse(filteredData, { header: true, delimiter: ";", quoteChar: '"' });
        let blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
        let url = URL.createObjectURL(blob);
        let link = document.createElement("a");
        link.setAttribute("href", url);
        link.setAttribute("download", "filteredData.csv");
        link.style.visibility = 'hidden';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    }

    return (
        <Container style={{ marginTop: "50px", marginBottom: "50px" }}>
            <Row>
                <Col md={12}>
                    <Card elevation={1}>
                        <div style={{display: "flex", justifyContent: "space-between"}}>
                            <div style={{maxWidth: "60%"}}>
                                <Button icon={!filtersCollapsed ? "caret-down" : "caret-up"}  onClick={() => {setFiltersCollapsed(!filtersCollapsed); setColumnsCollapsed(false)}}>Filter data</Button> <Button icon={!columnsCollapsed ? "caret-down" : "caret-up"}  onClick={() => {setColumnsCollapsed(!columnsCollapsed); setFiltersCollapsed(false)}}>Show/Hide columns</Button>
                                <Collapse isOpen={filtersCollapsed}>
                                    <Button intent='primary' style={{marginTop: "10px"}} icon="plus" onClick={() => setSelectedFilters([...selectedFilters, { index: -1, filter: ""  }])}>Add filter</Button>
                                    <p style={{fontSize: "0.9rem", marginTop: "15px", display: "inline-block", verticalAlign: "top", marginLeft: "10px"}}><Icon icon={"info-sign"} intent='primary'></Icon> <i>Separate multiple keywords by <b>;</b>. e.g. <b>p-acetyl;p-azido</b>.</i></p>
                                    {
                                        selectedFilters.map((val, idx) => {
                                            return (
                                                <Fragment>
                                                    <FormGroup style={{fontSize: "0.8rem", marginTop: "10px"}}>
                                                        <Select2<Filter>
                                                            items={filters ?? []}
                                                            itemRenderer={renderFilter}
                                                            filterable={false}
                                                            onItemSelect={val2 => updateFilter(val.filter, val2.index, idx)}
                                                            popoverProps={{ minimal: true }}
                                                            className='inline-block margin-right-10'
                                                        >
                                                            <Button style={{display: "inline-block"}} text={filters?.filter(v => v.index === val.index)[0].name ?? ""} rightIcon="double-caret-vertical" />
                                                        </Select2>
                                                        <InputGroup className='input-group-inline' style={{maxWidth: "200px"}} leftIcon="filter" value={val.filter} onChange={(event) => { updateFilter(event.target.value, val.index, idx); }}></InputGroup>
                                                        <Button intent="danger" style={{marginLeft: "5px"}} icon="trash" onClick={() => {
                                                            let newSelectedFilters = [...selectedFilters];
                                                            newSelectedFilters.splice(idx, 1);
                                                            setSelectedFilters(newSelectedFilters);
                                                            applySelectedFilters(newSelectedFilters);
                                                        }}></Button>
                                                    </FormGroup>
                                                    
                                                </Fragment>
                                            )
                                        })
                                    }

                                </Collapse>
                                <Collapse isOpen={columnsCollapsed} >
                                    <FormGroup style={{fontSize: "0.8rem", marginTop: "10px"}}>
                                        <Button onClick={
                                            () => {
                                                setDisplayedFields(fields?.map((val, idx) => idx) ?? []);
                                            }
                                        } >Show all</Button> <Button onClick={
                                            () => {
                                                setDisplayedFields([]);
                                            }
                                        }>Hide all</Button>
                                        <div>
                                        {fields?.map((val, idx) => {
                                            return (
                                                <Checkbox
                                                    style={{ display:"inline-block", width: "200px", verticalAlign: "top"  }}
                                                    checked={displayedFields.includes(idx)}
                                                    onChange={(event) => {
                                                        let newDisplayedFields = [...displayedFields];
                                                        if (event.currentTarget.checked) {
                                                            newDisplayedFields.push(idx);
                                                        } else {
                                                            newDisplayedFields = newDisplayedFields.filter((val) => val !== idx);
                                                        }
                                                        setDisplayedFields(newDisplayedFields);
                                                    }}
                                                >
                                                    {idx + 1}: {val.trim()}
                                                </Checkbox>
                                            )
                                        })
                                        }
                                        </div>
                                    </FormGroup>
                                </Collapse>
                                <p style={{fontSize: "0.9rem", marginTop: "15px"}}><Icon icon={"info-sign"} intent='primary'></Icon> <i>The table can be scrolled both vertically and horizontically.</i></p>
                                <p style={{fontSize: "0.9rem"}}><i><AiFillApple style={{ verticalAlign: "-4px", fontSize: "1.2rem", marginLeft: "-1.5px" }}/>Mac users might have to <b>click</b> on the table and use their arrow keys 
                                to scroll left-right <PiArrowSquareLeft style={{ verticalAlign: "-4px", fontSize: "1.2rem" }}/>
                                <PiArrowSquareRight style={{ verticalAlign: "-4px", fontSize: "1.2rem" }}/> and to scroll up-down <PiArrowSquareUp style={{ verticalAlign: "-4px", fontSize: "1.2rem" }}/>
                                <PiArrowSquareDown style={{ verticalAlign: "-4px", fontSize: "1.2rem" }}/> .</i></p>
                                <p style={{fontSize: "0.9rem"}}><i><MdColorLens style={{ verticalAlign: "-4px", fontSize: "1.2rem", marginLeft: "-1.5px" }}/>Entries are <b>colored</b> by corresponding publication.</i></p>
                            </div>
                            <div>
                                <div>
                                    <Tooltip content={`Columns are ;-separated, quotes are enclosed by "`} placement='bottom'>
                                        <AnchorButton icon="download" href='/entries.csv' rel="noreferrer" target='_blank' style={{marginRight: "4px"}}>Download All Data (.csv)</AnchorButton>
                                    </Tooltip>
                                    <Tooltip content={`Columns are ;-separated, quotes are enclosed by "`} placement='bottom'>
                                        <AnchorButton icon="download" rel="noreferrer" target='_blank' onClick={() => downloadFilteredData()}>Download Filtered Data (.csv)</AnchorButton>
                                    </Tooltip>
                                </div>
                            </div>
                        </div>
                        <div style={{display: "flex", justifyContent: "space-between", alignItems: "flex-end"}}>
                            <div style={{marginTop: "0px", fontSize: "0.9rem", display: "inline-flex", justifyContent: "flex-start", padding: "5px", marginBottom: "5px", border: "1px solid rgba(143, 153, 168, 0.15)"}}>
                                <p style={{marginTop: "2px"}}>Number of filtered (total):</p>
                                <table className='CounterTable'>
                                    <thead>
                                        <tr>
                                            <th>Entries</th>
                                            <th>Papers</th>
                                            <th>Proteins</th>
                                            <th>Synthetase/tRNA pairs <Tooltip content={`Unique pairs are determined by string comparison of the combination of "aaRS ID" and "tRNA sequence" columns.`} placement='top'><Icon icon={"info-sign"}></Icon></Tooltip></th>
                                            <th>ncAA</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td style={{minWidth: "100px"}}>{filteredData.length} ({data?.length})</td>
                                            <td style={{minWidth: "100px"}}>{numberOfUniquePapersFiltered} ({numberOfUniquePapersTotal})</td>
                                            <td style={{minWidth: "100px"}}>{numberOfProteinsFiltered} ({numberOfProteinsTotal})</td>
                                            <td style={{minWidth: "100px"}}>{numberOfUniqueSynthetasePairsFiltered} ({numberOfUniqueSynthetasePairsTotal})</td>
                                            <td style={{minWidth: "100px"}}>{numberOfUniqueNcAAFiltered} ({numberOfUniqueNcAATotal})</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <div>
                                <p style={{fontSize: "0.9rem"}}>Last updated: {modifiedDate?.toLocaleDateString('en-US', {year: "numeric", month: "long", day: "numeric"})}</p>
                            </div>
                        </div>
                        <div style={{ overflowY: "scroll", overflowX: "scroll", width: "100%", maxHeight: "700px" }}>
                            <HTMLTable bordered  condensed className='ResultsTable'>
                                <thead>
                                    <tr style={{position: "sticky", top: 0, zIndex: 4}}>
                                        {
                                            fields?.map((val, idx) => {
                                                return (
                                                     (!displayedFields.includes(idx)) ? null :
                                                    <th key={idx} style={{minWidth: "200px", backgroundColor: sortedFieldString === val ? "#F0F0F0" : ""}}>
                                                        {val.trim()}
                                                        {
                                                            (idx !== fastaIndex && idx !== structureIndex)
                                                            &&
                                                                <Fragment>
                                                                    {
                                                                        (sortedFieldString === val && sortedFieldAscending) ?
                                                                            <Button minimal icon="chevron-up" onClick={() => sortByField(val, false)}></Button>
                                                                            :
                                                                            <Button minimal icon="chevron-down" onClick={() => sortByField(val, true)}></Button>
                                                                    }
                                                                </Fragment>
                                                        }
                                                    </th>
                                                )
                                            })
                                        }
                                    </tr>
                                </thead>
                                <tbody>
                                    {filteredData.slice(page * perPage, page * perPage + perPage).map((value, idx) => {
                                        let values: any[] = Object.values(value);
                                        let color = paperToColorMap.get(value["Publication"]);
                                        return (
                                            <tr>
                                                {
                                                    values.map((val: any, ix) => {
                                                        if (!displayedFields.includes(ix)) return null;
                                                        if (ix === fastaIndex) {
                                                            if (val.includes("FASTA"))
                                                                return (
                                                                    <td key={ix} style={{backgroundColor: color}}>
                                                                        <div style={{ height: "150px", width: "200px", overflowY: "auto", overflowX: "auto", wordWrap: "break-word" }}>
                                                                            <a rel='noreferrer' target='_blank' href={"/FASTA/entry_" + values[0] + ".FASTA"}>Download FASTA</a>
                                                                        </div>
                                                                    </td>
                                                                );
                                                            else
                                                                return <td key={ix} style={{backgroundColor: color}}></td>
                                                        }
                                                        if (ix === structureIndex) {
                                                            if (val.includes("http"))
                                                                return (
                                                                    <td key={ix} style={{backgroundColor: color}}>
                                                                        <div style={{ height: "150px", width: "200px", overflowY: "auto", overflowX: "auto", wordWrap: "break-word", backgroundColor: "#F3F3F3", textAlign: "center" }}>
                                                                            <img alt="Structure" style={{ height: "140px" }} src={val}></img>
                                                                        </div>
                                                                    </td>
                                                                );
                                                            else
                                                                return <td key={ix} style={{backgroundColor: color}}>not available</td>
                                                        }
                                                        return (
                                                            <td key={ix} style={{backgroundColor: color}}>
                                                                <div style={{ height: "150px", width: "200px", overflowY: "auto", overflowX: "auto", wordWrap: "break-word" }}>
                                                                    {makeLinkIfLink(val)}
                                                                </div>
                                                            </td>)
                                                    }
                                                    )
                                                }
                                            </tr>
                                        )
                                    })}
                                </tbody>
                            </HTMLTable>
                        </div>
                        <div style={{ marginTop: "50px", marginLeft: "auto", marginRight: "auto", maxWidth: "800px" }}>
                            <ReactPaginate
                                previousLabel={'<'}
                                nextLabel={'>'}
                                breakLabel={'...'}
                                breakClassName={'break-me'}
                                pageCount={maxPages}
                                marginPagesDisplayed={1}
                                pageRangeDisplayed={3}
                                onPageChange={(data) => { setPage(data.selected); }}
                                containerClassName={'pagination'}
                                activeClassName={'active'}
                                forcePage={page}
                            />
                        </div>
                       
                    </Card>
                </Col>
            </Row>
        </Container>
    )
}

export default Table;



/*
OR FILTER
// Aply selected filters
        selectedFilters.forEach(element => {
            // Ignore empty filters
            if (element.filter === "") return;
            // Filter on all columns
            if (element.index === -1) {
                let thisFilterData: any[] = data?.filter(val => {
                    let values: string[] = Object.values(val);
                    // Make sure we only filter visible columns
                    values = values.filter((val, idx) => displayedFields.includes(idx));

                    let filteredValues = values.filter(value => {
                        if (value.toLocaleLowerCase().includes(element.filter.toLocaleLowerCase())) {
                            return true;
                        } else {
                            return false;
                        }
                    });
                    return filteredValues.length > 0;
                }) ?? [];
                
                console.log("thisfilterdata")
                console.log(thisFilterData);

                newFilteredData = newFilteredData?.concat(thisFilterData)
            // Filter on specific column
            } else {
                let thisFilterData: any[] = data?.filter(val => {
                    let values: string[] = Object.values(val);

                    let value = values.at(element.index);
                    if (value?.toLocaleLowerCase().includes(element.filter.toLocaleLowerCase())) {
                        return true;
                    } else {
                        return false;
                    }
                }) ?? [];

                newFilteredData = newFilteredData?.concat(thisFilterData)
            }
        });
*/