import './App.css';
import { CSSTransition, SwitchTransition } from "react-transition-group";
import { Navbar, NavbarGroup, NavbarHeading, NavbarDivider, Classes } from "@blueprintjs/core"
import Table from "./components/Table"
import Footer from './Footer';
import Home from './components/Home';
import { NavLink, Route, Routes, useLocation } from 'react-router-dom';
import UsefulInfo from './components/UsefulInfo';
import ContactUs from './components/ContactUs';
import Examples from './components/Examples';

function App() {
  const location = useLocation();

  return (
    <div className="App">
        <Navbar fixedToTop={true}>
          <NavbarGroup>
            <NavbarHeading>iNClusive</NavbarHeading>
            <NavbarDivider/>
            <NavLink className={
              ({isActive, isPending}) => 
               [Classes.BUTTON, Classes.MINIMAL, isActive ? Classes.ACTIVE : ""].join(" ")
              }  to="/">Home</NavLink>
            <NavLink className={
              ({isActive, isPending}) => 
               [Classes.BUTTON, Classes.MINIMAL, isActive ? Classes.ACTIVE : ""].join(" ")
              }  to="/info">Useful Information</NavLink>
            <NavLink className={
              ({isActive, isPending}) =>
              [Classes.BUTTON, Classes.MINIMAL, isActive ? Classes.ACTIVE : ""].join(" ")
              } to="/examples">Usage Examples</NavLink>
            <NavLink className={
              ({isActive, isPending}) => 
              [Classes.BUTTON, Classes.MINIMAL, isActive ? Classes.ACTIVE : ""].join(" ")
              } to="/table">Database</NavLink>
            <NavLink className={
              ({isActive, isPending}) =>
              [Classes.BUTTON, Classes.MINIMAL, isActive ? Classes.ACTIVE : ""].join(" ")
              } to="/contact">Contact</NavLink>
          </NavbarGroup>
        </Navbar>
        <SwitchTransition>
          <CSSTransition key={location.key} 
            classNames="fade" timeout={300}>
            <Routes location={location}>
              <Route path='/' element={<Home/>} />
              <Route path="/info" element={<UsefulInfo/>} />
              <Route path='/examples' element={<Examples/>} />
              <Route path='/table' element={<Table/>} />
              <Route path='/contact' element={<ContactUs/>} />
            </Routes>
          </CSSTransition>
        </SwitchTransition>
      <Footer/>
    </div>
  );
}

export default App;
